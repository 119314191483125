import axios from 'axios';
import { axiosinstance } from '../lib/axios';
import { loginProps, orderModifyProps, otpProps } from '../types/types';

// login
export const loginAdmin = async (payload: loginProps) => {
  try {
    const response = await axiosinstance.post('users/login', payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createUser = async (payload: any) => {
  try {
    const response = await axiosinstance.post('users/create', payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateUserStatus = async (payload: any) => {
  try {
    const response = await axiosinstance.patch('users/updateStatus', payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateRefund = async (payload: any) => {
  try {
    const response = await axiosinstance.post('orders/update-refund', payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateAdmin = async () => {
  const payload = {
    mobile: '7032375540',
    first_name: 'new user',
    email: 'b@gmail.com',
  };
  try {
    const response = await axiosinstance.put('users/update');
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getAdminProfile = async () => {
  try {
    const response = await axiosinstance.get('users/getUserDetails');
    return response;
  } catch (error) {
    return error.response;
  }
};

export const verifyOTP = async (payload: otpProps) => {
  try {
    const response = await axiosinstance.post('otp/verifyOtp', payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createReferralCode = async () => {
  const payload = {
    user_id: '47ee3d6a-9f0a-4f7c-a1e4-4c15a27af966',
  };
  try {
    const response = await axiosinstance.post(
      'referrals/create_referral_code',
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// dashboard - category

export const createCategory = async (payload: any) => {
  try {
    const response = await axiosinstance.post('categories/create', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateCategory = async (payload: any, categoryId: number) => {
  try {
    const response = await axiosinstance.put(
      `categories/update/${categoryId}`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getAllCategory = async () => {
  try {
    const response = await axiosinstance.get('categories/getAll');
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateCategoryStatus = async (
  status: boolean,
  categoryId: number
) => {
  try {
    const response = await axiosinstance.patch(
      `categories/update-active-status?cat_id=${categoryId}&status=${status}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// dashboard - items

export const createItem = async (payload: any) => {
  try {
    const response = await axiosinstance.post('items/create', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateItem = async (payload: any, item_id: number) => {
  try {
    const response = await axiosinstance.put(
      `items/update/${item_id}`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteItem = async (item_id: number | string) => {
  try {
    const response = await axiosinstance.delete(`items/delete/${item_id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateItemStatus = async (item_id: number, status: boolean) => {
  try {
    const response = await axiosinstance.patch(
      `items/update-item-status?item_id=${item_id}&status=${status}`
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const getAllItems = async (limit: number, page: number) => {
  console.log('Page from api', limit, page);
  const pagePayload = page + 1;
  try {
    const response = await axiosinstance.get(
      `items/getAll?limit=${limit}&page=${pagePayload}`
    );

    return response;
  } catch (error) {
    return error.response;
  }
};

// coupons

export const createCoupons = async (payload: any) => {
  try {
    const response = await axiosinstance.post('coupons/create', payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateCoupon = async () => {
  const payload = {
    conditions: {
      validItemIds: [11],
    },
  };
  try {
    const response = await axiosinstance.put('coupons/update/:id', payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getAllCoupons = async () => {
  try {
    const response = await axiosinstance.get('coupons/getAll');
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getCouponById = async (couponId) => {
  try {
    const response = await axiosinstance.get(`coupons/getOne/${couponId}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteCoupon = async (couponId) => {
  try {
    const response = await axiosinstance.delete(`coupons/delete/${couponId}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

// pages

export const createPages = async () => {
  const payload = {
    name: 'Terms & Conditions',
    content: '<h1>My First Heading</h1> <p>My first paragraph.</p>',
  };
  try {
    const response = await axiosinstance.post('pages/create', payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updatePage = async () => {
  const payload = {
    name: 'Privacy Policy',
    content: '<h1>My First Title</h1> <p>My first paragraph.</p>',
  };
  try {
    const response = await axiosinstance.put('pages/update/:page_id', payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getAllPages = async () => {
  try {
    const response = await axiosinstance.get('pages/getAllPages');
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getOnePage = async () => {
  try {
    const response = await axiosinstance.get('pages/getOne/:page_id');
    return response;
  } catch (error) {
    return error.response;
  }
};

// orders

export const getAllOrders = async (
  page: number,
  limit: number,
  riderMapIds?: number[],
  from?: Date,
  to?: Date
) => {
  try {
    const response = await axiosinstance.get(
      `orders/allOrdersForAllUsers?page=${
        page + 1
      }&limit=${limit}&orderBy=DESC&riderMapIds=${riderMapIds}&from=${from}&to=${to}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const orderModify = async ({
  deliveryTime,
  orderId,
  orderStatus,
}: orderModifyProps) => {
  try {
    const response = await axiosinstance.patch(
      `orders/updateOrderStatus/${orderId}`,
      {
        status: orderStatus,
        timeToDelivery: deliveryTime,
      }
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const getTodayOrders = async (status: string) => {
  try {
    const response = axiosinstance.get(`orders/todayOrders?status=${status}`);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const filterOrdersHistory = async (query: string) => {
  try {
    const response = axiosinstance.get(`orders/search?query=${query}`);
    return response;
  } catch (error) {
    return error.message;
  }
};

// users

export const getAllUsers = async (limit, page) => {
  try {
    const response = await axiosinstance.get(
      `users/all-users?limit=${limit}&page=${page + 1}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const filterUsers = async (query: string) => {
  try {
    const response = axiosinstance.get(`users/search?query=${query}`);
    return response;
  } catch (error) {
    return error.message;
  }
};

// dashboard - home
export const totalEarnings = async (isToday: boolean) => {
  try {
    const response = await axiosinstance.get(
      `payments/totalEarnings?today=${isToday}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const totalOrders = async (isToday: boolean) => {
  try {
    const response = await axiosinstance.get(
      `orders/totalOrders?today=${isToday}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// riders
export const getAllRiders = async () => {
  try {
    const response = await axiosinstance.get('rider/get-all-riders');
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getAllActiveRiders = async () => {
  try {
    const response = await axiosinstance.get('rider/get-all-active-riders');
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getRiderByRiderMapId = async (id: number) => {
  try {
    const response = await axiosinstance.get(`rider/get-rider-by-map-id/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const assignRider = async (payload: any) => {
  try {
    const response = await axiosinstance.post('orders/assign-order', payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getAllBanners = async () => {
  try {
    const response = await axiosinstance.get('banner/getAll');
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createBanner = async (payload: any) => {
  try {
    const response = await axiosinstance.post('banner/create', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteBanner = async (id: number) => {
  try {
    const response = await axiosinstance.delete(`banner/delete/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

// item options

export const createItemOptions = async (payload: any, item_id: number) => {
  try {
    const response = await axiosinstance.post(
      `items/add-options/${item_id}`,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateItemOptions = async (payload: any, item_id: number) => {
  try {
    const response = await axiosinstance.put(
      `items/update-options/${item_id}`,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createOrUpdateSettings = async (payload: any) => {
  try {
    const response = await axiosinstance.post('settings/create', payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateRestaurantStatusSettings = async (payload: any) => {
  try {
    const response = await axiosinstance.put('settings/update-status', payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getSettings = async () => {
  try {
    const response = await axiosinstance.get('settings');
    return response;
  } catch (error) {
    return error.response;
  }
};

export const searchMenuItems = async (query: string) => {
  try {
    const response = await axiosinstance.get(`items/search?query=${query}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteItems = async (itemId: number | string) => {
  try {
    const response = await axiosinstance.delete(`items/delete${itemId}`);
    return response;
  } catch (error) {
    return error.response;
  }
};
