import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import debounce from 'lodash.debounce';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Loading } from '../../components/loading/Loading';
import {
  filterOrdersHistory,
  getAllOrders,
  getAllRiders,
} from '../../services/api';
import BasicModalOrders from './BasicModalOrders';
import BasicUpdateOrderModal from '../../components/BasicUpdateOrderModal';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getColorCode(status) {
  const colorCodes = {
    new: 'blue', // Light blue
    preparing: '#FFA500', // Orange
    ready: 'black', // Yellow
    assigned: '#8A2BE2', // Blue Violet
    'picked up': '#008000', // Green
    delivered: '#00FF00', // Lime
    rejected: '#FF0000', // Red
    cancelled: '#808080', // Gray
    returned: '#800000', // Maroon
    failed: '#FF4500', // OrangeRed
    'on hold': '#FFD700', // Gold
    awaiting_payment: '#FF69B4', // HotPink
  };

  return colorCodes[status] || '#000000'; // Default to black if status not found
}

const OrderHistory = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [orderHistoryData, setOrderHistoryData] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [riderIds, setRiderIds] = React.useState<number[]>([]);
  const [dateRange, setDateRange] = useState({
    from: undefined,
    to: undefined,
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [riders, setRiders] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedRiderOrders, setSelectedRiderOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOrders, setFilterOrders] = useState([]);
  const [tableCount, setTableCount] = useState(0);
  const [updateOrderModal, setUpdateOrderModal] = useState(false);

  const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setSearchQuery(value);
    if (value) {
      debouncedSearchOrders(value);
    } else {
      setFilterOrders(orderHistoryData);
    }
  };

  const handleThreeDotsOpen = (event: { currentTarget: any }) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleThreeDotsClose = () => {
    setMenuAnchor(null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setSearchQuery('');
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSearchQuery('');
  };

  const handleMenuOpen = (event: { currentTarget: any }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const fetchAllOrderHistory = async () => {
    setLoading(true);
    setSearchQuery('');
    const response = await getAllOrders(
      page,
      rowsPerPage,
      riderIds,
      dateRange.from,
      dateRange.to
    );
    if (response?.status === 200) {
      console.log(response, 'vbnm');
      setTableCount(response?.data?.totalOrderCount);
      setOrderHistoryData(response?.data?.orders || []);
      setFilterOrders(response?.data?.orders || []);
    } else {
      toast(response?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllOrderHistory();
  }, [page, rowsPerPage]);

  const searchOrders = async (query: string) => {
    console.log('myquery', query);
    setLoading(true);
    try {
      const response = await filterOrdersHistory(query);

      if (response?.status === 200) {
        console.log('data', response, query);
        setFilterOrders(response?.data?.data || []);
      } else {
        setFilterOrders(orderHistoryData);
      }
    } catch (error) {
      console.error('Error fetching order history:', error);
      setFilterOrders(orderHistoryData);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearchOrders = useCallback(debounce(searchOrders, 300), []);

  useEffect(() => {
    return () => {
      debouncedSearchOrders.cancel();
    };
  }, []);

  useEffect(() => {
    fetchRiders();
  }, []);

  const fetchRiders = async () => {
    const response = await getAllRiders();
    if (response?.status === 200) {
      setRiders(response?.data);
    }
  };

  const handleChangeRiders = (e: any) => {
    setRiderIds(e?.target?.value);
  };

  const onClickRow = (event, order: any) => {
    setSelectedOrder(order);
    handleThreeDotsOpen(event);
  };

  const onClickUpdateOrder = () => {
    setUpdateOrderModal(true);
  };

  return (
    <div style={{ padding: '20px' }}>
      <Loading loading={loading} />
      <Toolbar
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          paddingLeft: '0px',
          paddingRight: '0px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: '5px',
          }}
        >
          <IconButton>
            <SearchIcon sx={{ color: 'rgba(252, 128, 25, 1)' }} />
          </IconButton>
          <TextField
            placeholder='Search here'
            variant='standard'
            InputProps={{ disableUnderline: true }}
            inputProps={{ style: { color: '#646465' } }}
            value={searchQuery}
            onChange={handleSearchQuery}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <Typography>From</Typography>
              <TextField
                type='date'
                onChange={(e) => {
                  setDateRange((prev) => ({
                    ...prev,
                    from: e.target.value,
                  }));
                }}
                size='small'
              />
            </Stack>
            {'---'}
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <Typography>To</Typography>
              <TextField
                type='date'
                onChange={(e) => {
                  setDateRange((prev) => ({
                    ...prev,
                    to: e.target.value,
                  }));
                }}
                size='small'
              />
            </Stack>
          </Box>
          <FormControl sx={{ width: 150 }} >
            <InputLabel id='demo-multiple-checkbox-label' sx={{marginTop: -1}}>Riders</InputLabel>
            <Select
              labelId='demo-multiple-checkbox-label'
              id='demo-multiple-checkbox'
              multiple
              value={riderIds}
              input={<OutlinedInput label='Riders' />}
              renderValue={(selected) =>
                selected
                  .map((id) => {
                    const rider = riders.find((r) => r?.rider_map_id === id);
                    return rider ? `${rider?.user?.first_name}` : '';
                  })
                  .join(', ')
              }
              MenuProps={MenuProps}
              // sx={{ height: 40 }}
              size='small'
              onChange={handleChangeRiders}
            >
              {riders &&
                riders.map((eachRider, index) => {
                  const riderName =
                    eachRider?.user?.first_name +
                    ' ' +
                    eachRider?.user?.last_name;
                  return (
                    <MenuItem key={index} value={eachRider?.rider_map_id}>
                      <Checkbox
                        checked={riderIds.indexOf(eachRider?.rider_map_id) > -1}
                      />
                      <ListItemText primary={riderName} />
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <Button variant='contained' onClick={() => fetchAllOrderHistory()}>
            Apply
          </Button>
        </Box>
      </Toolbar>
      <div
        style={{
          border: '1px solid #ccc',
          borderRadius: '10px',
          marginTop: '20px',
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Order
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Total Bill
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Order Status
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Payment Status
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Payment Mod
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Order Date
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Rider
                  </Typography>
                </TableCell>

                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders &&
                filteredOrders.length > 0 &&
                filteredOrders?.map((eachOrder, index) => (
                  <TableRow sx={{ cursor: 'pointer' }} key={index}>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      <div>
                        <Typography
                          variant='h5'
                          component='div'
                          style={{ fontSize: '16px' }}
                        >
                          Order # {eachOrder.orderNumber || 0}
                        </Typography>
                        <Typography
                          color='text.secondary'
                          style={{ fontSize: '13px' }}
                        >
                          Order Id {eachOrder?.order_id}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      &#x20B9; {eachOrder.payment?.amount || 0}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                        color: getColorCode(eachOrder?.status),
                      }}
                    >
                      {eachOrder.status?.toUpperCase() || ''}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                        color: getColorCode(eachOrder?.payment?.status),
                      }}
                    >
                      {eachOrder?.payment?.status?.toUpperCase() || ''}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      {eachOrder?.payment?.paymentType?.toUpperCase() || ''}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      {
                        moment.utc(eachOrder.createdAt).format('MMM DD, YYYY, hh:mm:ss A')
                      }
                </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      {eachOrder?.riderOrder &&
                      eachOrder?.riderOrder?.length > 0
                        ? eachOrder?.riderOrder.map(
                            (eachRiderOrder: any, index: number) => (
                              <p key={index}>
                                {eachRiderOrder?.rider_map?.user?.first_name}
                                {eachRiderOrder?.rider_map?.user?.last_name}
                              </p>
                            )
                          )
                        : 'Not assigned'}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: 'white',
                        }}
                      >
                        <IconButton onClick={(e) => onClickRow(e, eachOrder)}>
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          anchorEl={menuAnchor}
                          open={Boolean(menuAnchor)}
                          onClose={handleThreeDotsClose}
                        >
                          <MenuItem onClick={() => onClickUpdateOrder()}>
                            Update{' '}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setOpen(true);
                            }}
                          >
                            View
                          </MenuItem>
                        </Menu>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {searchQuery === '' && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component='div'
            count={tableCount || 5}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </div>

      {open && (
        <BasicModalOrders
          isHistory={true}
          order={selectedOrder}
          open={open}
          setOpen={setOpen}
        />
      )}

      {updateOrderModal && (
        <BasicUpdateOrderModal
          open={updateOrderModal}
          setOpen={setUpdateOrderModal}
          data={selectedOrder}
        />
      )}
    </div>
  );
};

export default OrderHistory;
