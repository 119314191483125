import { Button, Grid, Typography } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Loading } from '../../components/loading/Loading';
import { createOrUpdateSettings } from '../../services/api';
import { toast, ToastContainer } from 'react-toastify';


interface RestaurantHoursProps {
  opening_time: string;
  closing_time: string;
}
const RestaurantHours = ({ opening_time, closing_time }: RestaurantHoursProps) => {
  const [loading, setLoading] = useState(false);
  const [openTime, setOpenTime] = useState(null);
  const [closeTime, setCloseTime] = useState(null);

  const handleOpenTimeChange = (newTime) => {
    setOpenTime(newTime);
  };

  const handleCloseTimeChange = (newTime) => {
    setCloseTime(newTime);
  };

  const formatTime = (date) => {
    if (!date) return null;
    return moment(date).format('HH:mm:ss');
  };

  const shouldDisableTime = (time) => {
    if (!openTime) return false;
    
    const minCloseTime = moment(openTime).add(1, 'hour').toDate();
    return moment(time).isBefore(minCloseTime);
  };

  const handleSubmit = async () => {
    if (!openTime || !closeTime) return;
    try {
      setLoading(true);
      const response = await createOrUpdateSettings({
        opening_time: formatTime(openTime),
        closing_time: formatTime(closeTime),
      });
      if (response.status === 201) {
        toast.success('Restaurant hours updated successfully');
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    };
  };

  useEffect(() => {
    if (opening_time && closing_time) {
      // Convert time strings to Date objects
      const today = new Date();
      const [openHours, openMinutes] = opening_time.split(':');
      const [closeHours, closeMinutes] = closing_time.split(':');
      
      const openDate = new Date(today.setHours(Number(openHours), Number(openMinutes), 0));
      const closeDate = new Date(today.setHours(Number(closeHours), Number(closeMinutes), 0));
      
      setOpenTime(openDate);
      setCloseTime(closeDate);
    }
  }, [opening_time, closing_time]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {loading && <Loading loading={loading} />}
      
    <Typography mb={2} variant='h6'>Resturant Opening Hours</Typography>
      <Grid container spacing={3}>
        <Grid item>
          <TimePicker
            label="Opening Time"
            value={openTime}
            onChange={handleOpenTimeChange}
          />
        </Grid>
        <Grid item>
          <TimePicker
            label="Closing Time"
            value={closeTime}
            onChange={handleCloseTimeChange}
            shouldDisableTime={shouldDisableTime}
            slotProps={{
              textField: {
                helperText: openTime ? 'Must be at least 1 hour after opening time' : '',
              },
            }}
            // renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
      </Grid>
      <Button 
        variant="contained" 
        onClick={handleSubmit} 
        disabled={!openTime || !closeTime}
        sx={{
        width: "20%",
        mt: 2
      }}>
        Submit
      </Button>
      <ToastContainer />
    </LocalizationProvider>
  );
};

export default RestaurantHours;
