import {
  Button,
  Box,
  Stack,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  NativeSelect,
  Typography,
  FormControlLabel,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useEffect, useState } from 'react';
import { Loading } from '../../components/loading/Loading';
import {
  createCoupons,
  deleteCoupon,
  getAllItems,
  getCouponById,
} from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import { InputBox } from '../../components/InputBox/InputBox';
import IOSSwitch from '../../components/iosSwithc/IOSSwitch';
import { ModalButton } from '../../components/button/ModalButton';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  border: 'none',
  borderRadius: 0,
  width: '90%',
  maxWidth: '600px',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '50%',
    },
  },
};

type createCouponProps = {
  coupon_code: string | undefined;
  description: string | undefined;
  discountType: string | undefined;
  discountAmount: number | null;
  isActive: boolean;
  conditions: {
    minOrderValue: number | null;
    noOfRedeems: number | null;
    validItemIds: [];
    bogo: {
      itemIds: [];
    };
    freeItemByCartValue?: {
      freeItemIds: number[];
    };
  };
  expiresAt: Date;
};

export default function BasicModalCoupons({
  open,
  setOpen,
  couponId,
  isEdit,
  fetchCoupons,
}) {
  const [discountType, setDiscountType] = useState('percentage');
  const [couponItemIds, setCouponItemIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [menuItem, setMenuItem] = useState([]);
  const [isActiveCoupon, setIsActiveCoupon] = useState<boolean>(true);
  const [bogoChecked, setBogoChecked] = useState(false);
  const [freeItemChecked, setFreeItemChecked] = useState(false);
  const [couponPayload, setCouponPayload] = useState<createCouponProps>({
    coupon_code: undefined,
    description: undefined,
    discountType: undefined,
    discountAmount: null,
    isActive: true,
    conditions: {
      minOrderValue: null,
      noOfRedeems: null,
      validItemIds: [],
      bogo: {
        itemIds: [],
      },
      freeItemByCartValue: {
        freeItemIds: [],
      },
    },
    expiresAt: new Date(),
  });

  const [disabledInputs, setDisabledInputs] = useState({
    discountType: false,
    description: false,
    discountAmount: false,
    minOrderValue: false,
    coupon_code: false,
    free_item: false,
    bogo: false,
  });

  // item selected change
  const handleChanges = (event) => {
    const {
      target: { value },
    } = event;
    setCouponItemIds(value);
    setCouponPayload((prevState) => ({
      ...prevState,
      conditions: {
        ...prevState.conditions,
        validItemIds: value,
        bogo: {
          itemIds: bogoChecked ? value : [],
        },
        freeItemByCartValue: {
          freeItemIds: freeItemChecked ? value : [],
        },
      },
    }));
  };

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'minOrderValue' || name === 'noOfRedeems') {
      setCouponPayload((prevState) => ({
        ...prevState,
        conditions: {
          ...prevState.conditions,
          [name]: parseInt(value),
        },
      }));
    } else {
      setCouponPayload((prevState) => ({
        ...prevState,
        [name]: name === 'discountAmount' ? parseInt(value) : value,
      }));
    }
  };

  const discountTypeChange = (event) => {
    const value = event.target.value;
    setDiscountType(value);
    setCouponPayload((prevState) => ({
      ...prevState,
      discountType: value,
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    const response = await createCoupons(couponPayload);
    console.log(response);
    if (response?.status === 201) {
      setOpen(false);
      fetchCoupons();
    } else {
      toast(response?.data?.customMessage || 'Could not add coupon');
    }
    setLoading(false);
  };

  const fetchItems = async () => {
    setLoading(true);
    const response = await getAllItems(1000, 0);
    if (response?.status === 200) {
      setMenuItem([...response?.data?.data]);
    } else {
      toast(response?.data?.customMessage);
    }
    setLoading(false);
  };

  const fetchCouponById = async () => {
    setLoading(true);
    const response = await getCouponById(couponId);
    if (response?.status === 200) {
      const resData = response?.data;
      setCouponPayload((prevState) => ({
        ...prevState,
        ...resData,
      }));
      setCouponItemIds((prevState) => [
        ...(resData?.conditions?.validItemIds || ''),
      ]);
      console.log(resData);
      // disabling the inputs based of bogo
      if (resData?.conditions?.bogo?.itemIds?.length > 0) {
        setDisabledInputs({
          coupon_code: true,
          description: true,
          discountAmount: true,
          discountType: true,
          minOrderValue: true,
          free_item: true,
          bogo: false,
        });
        setBogoChecked(true);
      }
      if (resData?.conditions?.freeItemByCartValue?.freeItemIds?.length > 0) {
        setDisabledInputs({
          coupon_code: true,
          description: false,
          discountAmount: true,
          discountType: true,
          minOrderValue: false,
          free_item: false,
          bogo: true,
        });
        setFreeItemChecked(true);
      }
      setIsActiveCoupon(resData?.isActive);
    } else {
      toast(response?.data?.customMessage);
    }
    setLoading(false);
  };

  const deleteCoupons = async () => {
    setLoading(true);
    const response = await deleteCoupon(couponId);
    if (response?.status !== 200) {
      toast(response?.data);
    }
    setOpen(false);
    setLoading(false);
  };

  const handleSwitch = (e) => {
    const checked = e.target.checked;
    setIsActiveCoupon(checked);
    setCouponPayload((prevState) => ({
      ...prevState,
      isActive: checked,
    }));
  };

  const handleCloseModal = () => {
    setCouponPayload(null);
    setOpen(false);
  };

  useEffect(() => {
    fetchItems();
    isEdit && fetchCouponById();
  }, []);

  // disabling inputs based on check in modal
  const handleChangeCheckBox = (name: string) => {
    if (name === 'bogo' && !bogoChecked) {
      setBogoChecked(!bogoChecked);
      // disable inputs based on check in modal
      setDisabledInputs({
        coupon_code: true,
        description: true,
        discountAmount: true,
        discountType: true,
        minOrderValue: true,
        free_item: true,
        bogo: false,
      });
      setCouponPayload((prev) => ({
        ...prev,
        coupon_code: 'Buy 1 Get 1',
        description: 'Buy 1 and get 1 item for free',
        discountAmount: 0,
      }));
    } else if (name === 'bogo') {
      setBogoChecked(!bogoChecked);
      // disable inputs based on check in modal
      setDisabledInputs({
        coupon_code: false,
        description: false,
        discountAmount: false,
        discountType: false,
        minOrderValue: false,
        free_item: false,
        bogo: false,
      });
      setCouponPayload((prev) => ({
        ...prev,
        coupon_code: '',
        description: '',
      }));
    }

    if (name === 'freeItem' && !freeItemChecked) {
      setFreeItemChecked(!freeItemChecked);
      // disable inputs based on check in modal
      setDisabledInputs({
        coupon_code: true,
        description: true,
        discountAmount: true,
        discountType: true,
        minOrderValue: false,
        free_item: false,
        bogo: true,
      });
      setCouponPayload((prev) => ({
        ...prev,
        coupon_code: 'Free Item',
        description: 'Get free items',
        discountAmount: 0,
      }));
    } else if (name === 'freeItem') {
      setFreeItemChecked(!freeItemChecked);
      // disable inputs based on check in modal
      setDisabledInputs({
        coupon_code: false,
        description: false,
        discountAmount: false,
        discountType: false,
        minOrderValue: false,
        free_item: false,
        bogo: false,
      });
      setCouponPayload((prev) => ({
        ...prev,
        discountAmount: 0,
      }));
    }
  };

  return (
    <>
      {loading && <Loading loading={loading} />}
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        sx={{ padding: '0px' }}
      >
        <Box sx={style}>
          <Stack direction='row' justifyContent='space-between'>
            <span style={{ color: '#3D4152', fontWeight: '600' }}>
              Add Coupon
            </span>
            <span onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
              <CloseIcon />
            </span>
          </Stack>
          <InputBox
            value={couponPayload.coupon_code}
            name='coupon_code'
            onChange={handleInput}
            title={'Coupon Code'}
            type={'text'}
            disabled={disabledInputs?.coupon_code}
          />
          <InputBox
            value={couponPayload.description || ''}
            name='description'
            onChange={handleInput}
            title={'Coupon Description'}
            type={'text'}
            disabled={disabledInputs?.description}
          />

          <FormControl
            fullWidth
            sx={{ marginBottom: '10px' }}
            disabled={disabledInputs?.discountType}
          >
            <InputLabel
              variant='standard'
              htmlFor='uncontrolled-native'
              sx={{ fontSize: '20px' }}
            >
              Discount Type
            </InputLabel>
            <NativeSelect
              onChange={discountTypeChange}
              value={couponPayload.discountType || discountType}
              variant='filled'
            >
              <option value={'percentage'}>Percentage</option>
              <option value={'fixed'}>Fixed</option>
            </NativeSelect>
          </FormControl>
          <InputBox
            value={couponPayload.discountAmount || 0}
            name='discountAmount'
            onChange={handleInput}
            title={
              discountType === 'percentage'
                ? 'Discount Percentage'
                : 'Discount Amount'
            }
            type={'number'}
            disabled={disabledInputs?.discountAmount}
          />
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <InputBox
              value={couponPayload.conditions.minOrderValue || 0}
              name='minOrderValue'
              onChange={handleInput}
              title={'Minimum Order Value'}
              type={'number'}
              disabled={disabledInputs?.minOrderValue}
            />
            <InputBox
              value={couponPayload.conditions.noOfRedeems || 0}
              name='noOfRedeems'
              onChange={handleInput}
              title={'Number of Redeems'}
              type={'number'}
            />
            <InputBox
              value={couponPayload.expiresAt}
              name='expiresAt'
              onChange={handleInput}
              title={'Expires At'}
              type={'Date'}
            />
          </Stack>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <FormControlLabel
              disabled={disabledInputs?.bogo}
              control={
                <Checkbox
                  checked={bogoChecked}
                  onChange={() => handleChangeCheckBox('bogo')}
                />
              }
              label='Buy 1 Get 1 Item'
            />
            <FormControlLabel
              disabled={disabledInputs?.free_item}
              control={
                <Checkbox
                  checked={freeItemChecked}
                  onChange={() => handleChangeCheckBox('freeItem')}
                />
              }
              label='Free Item'
            />
          </Stack>
          <FormControl sx={{ width: '100%', marginTop: 2 }}>
            <InputLabel id='demo-multiple-checkbox-label'>Items</InputLabel>
            <Select
              labelId='demo-multiple-checkbox-label'
              id='demo-multiple-checkbox'
              multiple
              value={couponItemIds}
              onChange={handleChanges}
              input={<OutlinedInput label='Items' />}
              renderValue={(selected) =>
                selected
                  .map((id) => {
                    const item = menuItem.find((i) => i?.item_id === id);
                    return item ? `${item?.name}` : '';
                  })
                  .join(',')
              }
              MenuProps={MenuProps}
            >
              {menuItem && menuItem.length > 0 ? (
                menuItem.map((item, index) => (
                  <MenuItem key={index} value={item.item_id}>
                    <Checkbox
                      checked={couponItemIds.indexOf(item.item_id) > -1}
                    />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))
              ) : (
                <Typography>Items are not available</Typography>
              )}
            </Select>
          </FormControl>
          {isEdit && (
            <IOSSwitch onChange={handleSwitch} value={isActiveCoupon} />
          )}

          <Box
            sx={{
              marginTop: '25px',
              display: 'flex',
              justifyContent: isEdit ? 'space-between' : 'flex-end',
            }}
          >
            {isEdit && (
              <ModalButton
                color={'error.main'}
                title={'Delete'}
                onClick={deleteCoupons}
                disabled={false}
              />
            )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <ModalButton
                color={'secondary.main'}
                title={'Close'}
                onClick={handleCloseModal}
                disabled={false}
              />
              <ModalButton
                color={'primary.main'}
                title={'Save Changes'}
                onClick={handleSave}
                disabled={false}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
}
