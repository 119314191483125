import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Loading } from '../../components/loading/Loading';
import { getAllCoupons } from '../../services/api';
import BasicModalCoupons from './BasicModalCoupons';

function BasicCoupons({ couponsList }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [openCouponId, setOpenCouponId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const fetchCoupons = async () => {
    setLoading(true);
    const response = await getAllCoupons();
    if (response?.status === 200) {
      setCouponList([...response?.data]);
    } else {
      toast(response?.error?.customMessage);
    }
    console.log('Res', response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  return (
    <>
      {loading && <Loading loading={loading} />}
      <div style={{ padding: '20px' }}>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: 'white',
            paddingLeft: '0px',
            paddingRight: '0px',
            gap: '10px',
          }}
        >
          <Box>
            <Button
              sx={{
                backgroundColor: 'rgba(252, 128, 25, 1)',
                color: 'white',
                border: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(252, 128, 25, 1)',
                  color: 'white',
                  border: 'none',
                },
                textTransform: 'none',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                setOpenCouponId(null);
                setIsEdit(false);
                setOpen(true);
              }}
            >
              Add New Coupon
            </Button>
          </Box>
        </Toolbar>

        <Grid container spacing={2} sx={{ marginTop: '8px' }}>
          {couponList && couponList.length > 0 ? (
            couponList.map((row, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <div
                  style={{ border: '1px solid #ccc', borderRadius: '10px' }}
                  onClick={() => {
                    setIsEdit(true);
                    setOpenCouponId(row.coupon_id);
                    setOpen(true);
                  }}
                >
                  <div
                    style={{
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      marginTop: '10px',
                      marginBottom: '10px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      variant='contained'
                      sx={{
                        backgroundColor: row.isActive ? '#EB5757' : 'grey',
                        color: 'white',
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                        padding: '3px 8px',
                        boxShadow: 'none',
                        '&:hover': {
                          backgroundColor: '#EB5757',
                          boxShadow: 'none',
                        },
                      }}
                      disableElevation
                      disableRipple
                    >
                      {row.coupon_code}
                    </Button>
                    <Typography
                      mx={2}
                      sx={{
                        color: row.isActive ? 'green' : 'red',
                        fontWeight: 'bold',
                      }}
                    >
                      {row.isActive ? 'Activate' : 'Deactivated'}
                    </Typography>
                  </div>

                  <div style={{ padding: '0px 20px 10px 20px' }}>
                    <Typography
                      variant='h6'
                      fontWeight={700}
                      sx={{ color: '#3D4152' }}
                    >
                      Coupon Code : {row.coupon_code}
                    </Typography>
                    <Typography color='text.secondary'>
                      Discount Amount : &#x20B9;{row.discountAmount}
                    </Typography>
                    <Typography
                      color='text.secondary'
                      textTransform={'capitalize'}
                    >
                      Discount Type : {row.discountType}
                    </Typography>
                    <Typography
                      color='text.secondary'
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      Description : {row.description}
                    </Typography>
                    <Typography color='text.secondary'>
                      Created At :{' '}
                      {
                        moment.utc(row.createdAt).format('MMMM DD, YYYY, hh:mm:ss A')
                      }
                    </Typography>
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <Typography>Coupons are not available</Typography>
          )}
        </Grid>
      </div>
      {open && (
        <BasicModalCoupons
          open={open}
          setOpen={setOpen}
          couponId={openCouponId}
          isEdit={isEdit}
          fetchCoupons={fetchCoupons}
        />
      )}
    </>
  );
}

export default BasicCoupons;
