import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
  ButtonGroup,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import * as React from 'react';
import NonvegIcon from '../../assets/icons/non-veg.png';
import VegIcon from '../../assets/icons/veg.png';
import { OutlinedButton } from '../../components/button/ModalButton';
import {
  assignRider,
  getRiderByRiderMapId,
  orderModify,
} from '../../services/api';
import { orderModifyProps } from '../../types/types';
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from 'react-toastify';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import OrderKotModal, { OrderPrintModal } from '../../components/OrderKot';
import { getOrdinalSuffix } from '../../utils/commonFunctions';
import VerifiedIcon from '@mui/icons-material/Verified';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  border: 'none',
  borderRadius: 0,
  width: '90%',
  maxWidth: '600px',
  maxHeight: '80%',
  overflowY: 'auto',
};

type BasicModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  order: any;
  stopIt?: () => void;
  playIt?: () => void;
  activeRiders?: any;
  isHistory?: boolean;
  fetchTodayOrder?: (status: string) => Promise<void>;
  status?: string;
};

export default function BasicModalOrders({
  open,
  setOpen,
  order,
  stopIt,
  playIt,
  activeRiders,
  isHistory,
  fetchTodayOrder,
  status,
}: BasicModalProps) {
  const [muted, setMuted] = React.useState<boolean>(false);
  const [waitTime, setWaitTime] = React.useState<number>(15);
  const [riderDetails, setRiderDetails] = React.useState<any>({});
  const [selectedAssignRider, setSelectedAssignRider] = React.useState<any>();
  const [kotOpen, setKotOpen] = React.useState<boolean>(false);
  const [orderPrintOpen, setOrderPrintOpen] = React.useState<boolean>(false);

  const handleMute = () => {
    setMuted(!muted);
    if (muted) {
      playIt();
    } else {
      stopIt();
    }
  };

  const handleTimer = (increment: boolean) => {
    if (increment) {
      setWaitTime((prevState) => prevState + 1);
    } else {
      if (waitTime > 10) {
        setWaitTime((prevState) => prevState - 1);
      }
    }
  };

  function getOrderTotal() {
    const itemTotal = order?.totalItemPriceAfterDiscount || 0;
    const GST = order?.gst || 0;
    const deliveryFee = order?.deliveryFee || 0;
    const packagingCharges = order?.platformFee || 0;
    const total = itemTotal + GST + deliveryFee + packagingCharges;
    return total || 0;
  }

  const updateOrderStatus = async (status: string) => {
    const reqBody: orderModifyProps = {
      orderId: 0,
      orderStatus: '',
    };
    reqBody['orderId'] = order?.order_id;
    reqBody['orderStatus'] = status;
    if (status === 'preparing') {
      reqBody['deliveryTime'] = waitTime;
    }
    const response = await orderModify(reqBody);
    if (response.status === 200) {
      toast('Order updated successfully');
    }
    fetchTodayOrder(status);
    setOpen(false);

    window.location.reload();
  };

  const acceptAndRejectOrder = (status: string) => {
    stopIt();
    setOpen(false);
    updateOrderStatus(status);
  };

  const getRiderByMapId = async (mapId: number) => {
    const response = await getRiderByRiderMapId(mapId);
    if (response?.status === 200) {
      setRiderDetails(response?.data);
    }
  };

  const assignOrderToRider = async () => {
    const response = await assignRider({
      order_id: order?.order_id,
      rider_map_id: selectedAssignRider,
    });
    if (response?.status === 201) {
      toast(`Rider assigned to order ${order?.order_id}`);
    }
    setOpen(false);
    fetchTodayOrder(status);
  };

  React.useEffect(() => {
    if (order?.status === 'assigned') {
      getRiderByMapId(order?.riderOrder?.rider_map?.rider_map_id);
    }
  }, []);

  return (
    <div>
      <OrderKotModal open={kotOpen} setOpen={setKotOpen} order={order} />
      <OrderPrintModal
        open={orderPrintOpen}
        setOpen={setOrderPrintOpen}
        order={order}
      />
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems={'center'}
          >
            <Stack direction={'row'} alignItems={'center'} gap={2}>
              <span style={{ color: '#3D4152', fontWeight: '600' }}>
                Order Details
              </span>
              <Stack direction={'row'} gap={2}>
                <Button
                  variant='outlined'
                  onClick={() => setKotOpen(true)}
                  startIcon={<LocalPrintshopIcon />}
                >
                  Kot
                </Button>
                <Button
                  onClick={() => setOrderPrintOpen(true)}
                  variant='outlined'
                  startIcon={<LocalPrintshopIcon />}
                >
                  Order
                </Button>
              </Stack>
            </Stack>
            <span onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
              <CloseIcon />
            </span>
          </Stack>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            my={2}
          >
            {order?.status === 'new' && (
              <OutlinedButton
                Icon={muted ? VolumeOffIcon : VolumeUpIcon}
                disabled={false}
                onClick={handleMute}
                title={muted ? 'Mute' : 'Unmute'}
              />
            )}
            {order?.status === 'preparing' && (
              <Typography
                sx={{
                  color: 'primary.main',
                }}
              >
                Delivery time - {order?.timeToDelivery} mins
              </Typography>
            )}
          </Box>
          <div
            style={{
              border: '1px solid #ccc',
              borderRadius: '5px',
              padding: '10px',
            }}
          >
            <Box py={1}>
              <Typography color='text.secondary' style={{ fontSize: '1em' }}>
                Order Id
              </Typography>
              <Typography
                color=''
                variant='h6'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: 'calc(100% - 24px)',
                }}
              >
                {order?.order_id}
              </Typography>
              <Typography
                color='text.secondary'
                style={{ fontSize: '0.9em' }}
              ></Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                
              }}
              py={1}
            >
              <Box>
                <Typography>Order # {order?.orderNumber || 0}</Typography>
                <Typography color='text.secondary' style={{ fontSize: '13px' }}>
                  {moment.utc(order?.createdAt).format('MMMM DD, YYYY, hh:mm:ss A')}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  color='text.secondary'
                  fontWeight={'bold'}
                  style={{ fontSize: '15px' }}
                >
                  {order?.customerOrderCount ? getOrdinalSuffix(order?.customerOrderCount) : null} {" "}
                  Order by{' '}
                  {order?.customerOrderCount >= 3 && <IconButton size='small' sx={{ml: 1}}>
                          <VerifiedIcon sx={{color: "#fc8019", width: "20px", height: "20px"}} />
                  </IconButton>}
                  <Typography fontSize={'14px'} textTransform={'capitalize'}>
                    {order?.userMap?.user?.first_name}{' '}
                    {order?.userMap?.user?.last_name}
                  </Typography>
                  <Typography fontSize={'14px'}>
                    {order?.userMap?.user?.mobile}
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box py={1}>
              <Typography color='text.secondary' style={{ fontSize: '1em' }}>
                Delivery Address
                <PlaceOutlinedIcon
                  sx={{
                    color: 'rgba(252, 128, 25, 1)',
                    fontSize: '12px',
                    marginLeft: '5px',
                  }}
                />
              </Typography>
              <Typography
                color=''
                variant='body2'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  wordBreak: 'break-word',
                }}
              >
                {order?.address?.street || '---'}
              </Typography>
            </Box>
            <Divider />
            <Box py={1}>
              <Typography color='text.secondary' style={{ fontSize: '1em' }}>
                Instructions
              </Typography>
              <Typography
                color=''
                variant='h6'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: 'calc(100% - 24px)',
                }}
              ></Typography>
              <Typography color='error.main' style={{ fontSize: '0.9em' }}>
                {order?.instructions || '---'}
              </Typography>
            </Box>
            <Divider />
            <Box py={1}>
              <Typography
                color='text.secondary'
                style={{ fontSize: '1em' }}
                mb={1}
              >
                Order Menu
              </Typography>

              {order &&
                order?.items?.map(
                  (
                    item: {
                      imageUrl: string;
                      parent: { name: string };
                      name:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | Iterable<React.ReactNode>;
                      isVeg: any;
                      quantity:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | Iterable<React.ReactNode>
                        | React.ReactPortal;
                      discountPrice: any;
                    },
                    index: React.Key
                  ) => (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '10px',
                      }}
                      key={index}
                    >
                      <Box sx={{ display: 'flex', marginBottom: '0px' }}>
                        <Box
                          style={{
                            border: '1px solid #fc8019',
                            borderRadius: '5px',
                          }}
                        >
                          <img
                            src={item?.imageUrl}
                            alt=''
                            style={{
                              width: '43px',
                              height: '43px',
                              borderRadius: '10px',
                              objectFit: 'cover',
                            }}
                          />
                        </Box>
                        <Box sx={{ marginLeft: '10px' }}>
                          <Typography
                            style={{
                              fontSize: '15px',
                              fontWeight: '100',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 10,
                            }}
                          >
                            {item?.parent
                              ? item?.parent?.name + '(' + item?.name + ')'
                              : item?.name}
                            <img
                              src={item?.isVeg ? VegIcon : NonvegIcon}
                              width={15}
                              height={15}
                              alt=''
                            />
                          </Typography>
                          <Typography
                            color='text.secondary'
                            style={{ fontSize: '13px' }}
                          >
                            x{item?.quantity}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: '1.2em',
                            fontWeight: '100',
                            color: 'primary.main',
                          }}
                        >
                          &#x20B9;{item?.discountPrice || 0}
                        </Typography>
                      </Box>
                    </div>
                  )
                )}
            </Box>
            <Divider />
            <Box py={1}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography fontSize={'0.9em'} color='text.secondary'>
                  Item
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1em',
                    color: 'text.secondary',
                  }}
                >
                  &#x20B9;{order?.totalItemPriceAfterDiscount || 'N/A'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography fontSize={'0.9em'} color='text.secondary'>
                  GST
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1em',
                    color: 'text.secondary',
                  }}
                >
                  &#x20b9;{order?.gst || 'N/A'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography fontSize={'0.9em'} color='text.secondary'>
                  Delivery Charges
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1em',
                    color: 'text.secondary',
                  }}
                >
                  &#x20B9;{order?.deliveryFee || 'N/A'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography fontSize={'0.9em'} color='text.secondary'>
                  Packaging Charges
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1em',
                    color: 'text.secondary',
                  }}
                >
                  &#x20B9;{order?.platformFee || 'N/A'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography fontSize={'0.9em'} color='text.secondary'>
                  Coupon Code
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1em',
                    color: 'text.secondary',
                  }}
                >
                  {order?.coupon_claim?.coupon_data?.coupon_code || 'N/A'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography fontSize={'0.9em'} color='text.secondary'>
                  Coupon Discount Amount
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1em',
                    color: 'text.secondary',
                  }}
                >
                  &#x20B9;
                  {order?.coupon_claim?.coupon_data?.discountAmount || 'N/A'}
                </Typography>
              </Box>
              {order?.walletPoints > 0 && <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography fontSize={'0.9em'} color='text.secondary'>
                  Wallet Points
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1em',
                    color: 'text.secondary',
                  }}
                >
                  &#x20B9;
                  {order?.walletPoints || 'N/A'}
                </Typography>
              </Box>}
              <Divider />
              <Box
                mt={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  fontSize={'1rem'}
                  fontWeight={'bold'}
                  color='text.secondary'
                >
                  Total Payment - ({order?.payment?.paymentType?.toUpperCase()})
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1.3em',
                    color: 'text.secondary',
                  }}
                >
                  &#x20b9;{order?.payment?.amount}
                </Typography>
              </Box>

              
              {order?.payment?.refund && (
                <>
                  <Divider />
                  <Typography
                    sx={{
                      mt: 2,
                      fontWeight: 'bold',
                    }}
                  >
                    Refund Details{' '}
                  </Typography>
                  <Box
                    mt={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography fontSize={'0.9em'} color='text.secondary'>
                      Razorpay Refund Id
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '1em',
                        color: 'text.secondary',
                      }}
                    >
                      {order?.payment?.refund?.razorpay_refund_id}
                    </Typography>
                  </Box>
                  <Box
                    mt={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography fontSize={'0.9em'} color='text.secondary'>
                      {' '}
                      Refund Status
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '1em',
                        color: 'text.secondary',
                      }}
                    >
                      {order?.payment?.refund?.refund_status ===
                      'fully_refunded'
                        ? 'Full Refund'
                        : 'Partial Refund'}
                    </Typography>
                  </Box>
                  <Box
                    mt={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography fontSize={'0.9em'} color='text.secondary'>
                      {' '}
                      Refund Amount
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '1em',
                        color: 'text.secondary',
                      }}
                    >
                      {order?.payment?.refund?.amount}
                    </Typography>
                  </Box>
                  <Box
                    mt={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography fontSize={'0.9em'} color='text.secondary'>
                      {' '}
                      Refund Date
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '1em',
                        color: 'text.secondary',
                      }}
                    >
                      {order?.payment?.refund?.refundedAt}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
            <Divider />
            {order?.status === 'assigned' && (
              <Box py={1}>
                <Typography py={1}>Rider Details</Typography>
                <TableContainer
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                  }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                          Name
                        </TableCell>
                        <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                          Mobile
                        </TableCell>
                        <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order?.riderOrder &&
                        order?.riderOrder.map(
                          (eachRiderOrder: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell
                                style={{ borderBottom: '1px solid #ccc' }}
                              >
                                {eachRiderOrder?.rider_map?.user?.first_name}{' '}
                                {eachRiderOrder?.rider_map?.user?.last_name}
                              </TableCell>
                              <TableCell
                                style={{ borderBottom: '1px solid #ccc' }}
                              >
                                {eachRiderOrder?.rider_map?.user?.mobile}
                              </TableCell>
                              <TableCell
                                style={{ borderBottom: '1px solid #ccc' }}
                              >
                                {eachRiderOrder?.status}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </div>
          <Box padding={'10px'}>
            {!isHistory && (
              <Box>
                {order?.status === 'ready' && (
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        my: 2,
                        fontSize: '1.2rem',
                      }}
                    >
                      Assign Rider
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        Rider
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        label='Age'
                        value={selectedAssignRider}
                        onChange={(e) => setSelectedAssignRider(e.target.value)}
                      >
                        {activeRiders &&
                          activeRiders.map(
                            (
                              eachRider: {
                                rider_map_id:
                                  | string
                                  | number
                                  | readonly string[];
                                user: {
                                  first_name:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        | string
                                        | React.JSXElementConstructor<any>
                                      >
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal;
                                  last_name:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        | string
                                        | React.JSXElementConstructor<any>
                                      >
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal;
                                };
                              },
                              index: React.Key
                            ) => (
                              <MenuItem
                                key={index}
                                value={eachRider?.rider_map_id}
                              >
                                {eachRider?.user?.first_name}{' '}
                                {eachRider?.user?.last_name}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Box>
                )}
                {order?.status === 'assigned' && (
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        my: 2,
                        fontSize: '1.2rem',
                      }}
                    >
                      Assign Rider
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        Rider
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        label='Age'
                        value={selectedAssignRider}
                        onChange={(e) => setSelectedAssignRider(e.target.value)}
                      >
                        {activeRiders &&
                          activeRiders.map(
                            (
                              eachRider: {
                                rider_map_id:
                                  | string
                                  | number
                                  | readonly string[];
                                user: {
                                  first_name:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        | string
                                        | React.JSXElementConstructor<any>
                                      >
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal;
                                  last_name:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        | string
                                        | React.JSXElementConstructor<any>
                                      >
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal;
                                };
                              },
                              index: React.Key
                            ) => (
                              <MenuItem
                                key={index}
                                value={eachRider?.rider_map_id}
                              >
                                {eachRider?.user?.first_name}{' '}
                                {eachRider?.user?.last_name}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Box>
                )}
                {order?.status === 'new' && (
                  <ButtonGroup
                    variant='outlined'
                    aria-label='Basic button group'
                    sx={{ width: '100%', margin: '20px 0' }}
                  >
                    <Button
                      style={{ width: '20%', fontWeight: 'bold' }}
                      onClick={() => {
                        handleTimer(false);
                      }}
                      disabled={waitTime === 10}
                    >
                      &#8212;
                    </Button>
                    <Button
                      disabled
                      style={{
                        width: '60%',
                        color: '#fc8019',
                        border: '1px solid rgb(255 189 134)',
                      }}
                    >
                      {waitTime} mins
                    </Button>
                    <Button
                      style={{ width: '20%', fontSize: '1.2em' }}
                      onClick={() => {
                        handleTimer(true);
                      }}
                    >
                      &#xFF0B;
                    </Button>
                  </ButtonGroup>
                )}
                {order?.status === 'new' && (
                  <Box
                    sx={{
                      marginTop: '15px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      onClick={() => acceptAndRejectOrder('rejected')}
                      sx={{
                        backgroundColor: 'white',
                        color: '#EB5757',
                        border: '1px solid red',
                        '&:hover': {
                          backgroundColor: '#EB5757',
                          color: 'white',
                        },
                        marginRight: '10px',
                      }}
                    >
                      Reject Order
                    </Button>
                    <Button
                      sx={{
                        backgroundColor: 'rgba(252, 128, 25, 1)',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'rgba(252, 128, 25, 1)',
                          color: 'white',
                        },
                      }}
                      onClick={() => {
                        acceptAndRejectOrder('preparing');
                      }}
                    >
                      Accept Order
                    </Button>
                  </Box>
                )}
                {order?.status === 'preparing' && (
                  <Box
                    sx={{
                      marginTop: '15px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      sx={{
                        backgroundColor: 'white',
                        color: '#EB5757',
                        border: '1px solid red',
                        '&:hover': {
                          backgroundColor: '#EB5757',
                          color: 'white',
                        },
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        acceptAndRejectOrder('ready');
                      }}
                    >
                      Move To Ready
                    </Button>
                  </Box>
                )}
                {order?.status === 'ready' && (
                  <Box
                    sx={{
                      marginTop: '15px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      sx={{
                        backgroundColor: 'white',
                        color: '#EB5757',
                        border: '1px solid red',
                        '&:hover': {
                          backgroundColor: '#EB5757',
                          color: 'white',
                        },
                        marginRight: '10px',
                      }}
                      onClick={() => assignOrderToRider()}
                    >
                      Assign To Rider
                    </Button>
                  </Box>
                )}
                {order?.status === 'assigned' && (
                  <Box
                    sx={{
                      marginTop: '15px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      sx={{
                        backgroundColor: 'white',
                        color: '#EB5757',
                        border: '1px solid red',
                        '&:hover': {
                          backgroundColor: '#EB5757',
                          color: 'white',
                        },
                        marginRight: '10px',
                      }}
                      onClick={() => assignOrderToRider()}
                    >
                      Change Rider
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
